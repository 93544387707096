.alert-tag-list {
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5em;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.alert-tag-item {
  padding: 0;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 0.30em;
  background-color: #0078ab;
  color: white;
  border-radius: 1.1em;
}

.alert-tag-remove {
  color: white;
  padding: 4px;
  font-size: 16px;
  cursor: pointer;
  background-color: #0078ab;
  border-radius: 1em;

  path {
    fill: white;
  }
}

.alert-tag-item:hover {
  .alert-tag-remove {
    transition: all 0.2s ease-in-out;
    background-color: #7fbbd4;
  }
}