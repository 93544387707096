@import "../../styles/brand-colors";

.main-buttons-container {
  display: flex;
  justify-content: right;
  align-items: center;
  min-height: 48px;
  margin-bottom: -3em;
}

.main-button {
  font-style: normal;
  font-size: 14px !important;
  padding: 0.25em 0.5em !important;
  z-index: 1;
}

.tab-body {
  position: relative;
  height: 420px;
  padding-top: 0 !important;
}

.edit-panel-sidebar {
  background: white;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.actions-right {
  text-align: right;
}

.actions-left {
  text-align: left;
}

div.alert-detail-table-over {
  height: 400px;
  overflow-x: visible;
  overflow-y: hidden;
}

.action-button {
  color: $dusk !important;
  font-size: 16px;
  text-decoration: none;
  height: 20px;
  width: 20px;
}


.row-header-table,
.actions-center,
.row-data-table {
  font-family: "Nunito Sans", "Wigrum", serif !important;
  font-style: normal;
  font-size: 14px;
  color: $gray-two;
}

.row-header-table {
  font-weight: 800;
  font-size: 14px;
  text-align: center;
  width: 100% !important;
}

.row-header-table-fixed {
  table-layout: fixed;
}

.row-data-table {
  font-weight: normal;
}

.row-data-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.tag-text {
  justify-content: left;
}

.modal-fix > div {
  z-index: 1000;
}