@import "../../styles/brand-colors";

.edit-panel-container {
  height: 100%;
  max-height: 100%;
  overflow-y: hidden;
  width: 33%;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: -2px 1px 3px $dusk-thirty;
}

.edit-panel-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0.25em 0.5em;

  p {
    margin: 0;
    padding: 0;
  }
}

.apply-button {
  font-style: normal;
  font-size: 14px;
  padding: 0.25em 0.5em;
  z-index: 1;
}

.edit-panel-content {
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  span, input, p, textarea {
    font-size: 14px !important;
    text-align: left !important;
    font-family: "Nunito Sans", "Wigrum", serif !important;
  }
}

.edit-panel-textarea {
  display: flex;
  flex-direction: column;
  width: 100%;

  textarea {
    border: 1px solid #ccd6db;
    padding: 0.25em 0 0.25em 0.5em;
  }

  p {
    margin: 0;
    padding: 0;
  }
}

.edit-panel-input {
  input {
    height: content-box;
    margin: 0 0.5em;
  }
}

.edit-panel-select {
  display: flex;
  flex-direction: column;
  width: 100%;

  p {
    margin: 0 0 3px;
    padding: 0;
  }
}

.edit-panel-sidebar-active {
  background: white;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}