@import "../../styles/brand-colors";

.title-panel,
.subtitle-panel {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
}

.title-panel,
.subtitle-panel {
  font-family: "Nunito Sans", "Wigrum" !important;
}

.icon-align {
    text-align: right !important;
}

.card {
  border: 1px solid $midnight-20;
  border-radius: 4px !important;
}

.card-header {
  background-color: $card-background-color !important;
  border-bottom: 1px solid $midnight-20 !important;
  border-radius: 2px !important;
}

.card-header-blank {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: white !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.title-panel {
  color: $gray-two;
}

.title-alerts-details-panel {
  color: $midnight-20 !important;
}

.subtitle-panel {
  color: $sunset;
}
.button-download-units {
  color: $lake !important;
  font-size: 16px;
  margin: 0;
  text-decoration: none;
}

.alert-card-container {
  flex: 1 1 auto;
  padding: 0 !important;
}