@import "../../styles/brand-colors";

.alert-container {
  display: flex;
  margin: 12px 2px;
}

.alert-header-container {
  cursor: pointer;
}

.alert-actions-container {
  display: flex;
  justify-content: flex-end;
}

.title-alert-details,
.subtitle-alert-details,
.unit-alert-details,
.footer-message-alerts-details,
.footer-button-alerts-details {
  font-family: "Nunito Sans", "Wigrum", serif !important;
  font-style: normal;
}

.title-alert-details,
.subtitle-alert-details,
.small-subtitle-alert-details {
  color: $dusk-light;
}

.alert-list-container {
  height: 480px;
  overflow-x: hidden;
  overflow-y: auto;
}

.title-alert-details,
.subtitle-alert-details,
.unit-alert-details {
  font-size: 16px;
  line-height: 22px;
}

.title-alert-details,
.small-subtitle-alert-details {
  font-weight: 800;
}

.subtitle-alert-details,
.footer-message-alerts-details {
  font-weight: normal;
}

.small-subtitle-alert-details,
.footer-message-alerts-details,
.footer-button-alerts-details {
  font-size: 12px;
  line-height: 16px;
}

.unit-alert-details {
  font-weight: 600;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

.footer-message-alerts-details {
  color: $dusk-lightest;
}

.footer-button-alerts-details {
  font-weight: bold;
  color: $black;
  text-align: right;
}

.footer-line-alerts-details {
  border: 1px solid $dusk-zero;
}

.tag-alert-button {
  margin: auto;
  padding: 1em;
  cursor: pointer;
  border-radius: 100%;
  border: 1px solid rgba(0, 0, 0, 0.25);
  width: 26px;
  height: 26px;
  path {
    fill: rgba(0, 0, 0, 0.6)
  };
}

.tag-alert-button:hover {
  transition: all 0.2s ease-in-out;
  background-color: rgba(0, 0, 0, 0.125);
}

