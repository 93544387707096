@import "../../../styles/brand-colors";

.suggestion-panel-container {
  border-left: 1px solid $midnight-20 !important;
  padding: 0 18px;
}

.title-suggestion-panel,
.info-suggestion-panel {
  font-family: "Nunito Sans", "Wigrum", sans-serif !important;
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
}

.title-suggestion-panel {
  font-weight: 800;
}

.info-suggestion-panel {
  font-weight: normal;
  text-align: justify;
  padding-left: 18px;
}

.suggestion-panel-children {
  display: flex;
  justify-content: flex-end;
}
