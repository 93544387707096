@import 'styles/brand-colors';
@import 'styles/font-style';
@import "~bootstrap/scss/bootstrap.scss";

body {
  background-color: transparent;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.as-link {
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

.table {
  .badge {
    min-width: 42px;
  }
}
