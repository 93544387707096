.maintenance-image {
  width: 100%;
  max-height: 250px;
}

.dynamic-tabs {
  position: relative;
}

.vl {
  position: relative;
  border-left: 1px solid #CCD6DB;
  height: auto;
}
