@import "../../../styles/brand-colors";

.title-panel-not-selected-data,
.subtitle-panel-not-selected-data {
  text-align: center;
}

.title-panel-not-selected-data {
  font-family: "Wigrum";
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.3px;
  color: $midnight;
}

.subtitle-panel-not-selected-data {
  font-family: "Nunito Sans", "Wigrum" !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $dusk;
}