@import "../../styles/brand-colors";

.table {
  // display: inline-block;
  // border-collapse: collapse;
  // min-width: 580px;

  .tr {
    :last-child {
      .td {
        border-bottom: 0;
      }
    }
  }

  .th,
  .td {
    text-align: center;
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid $midnight-30;
  }
}

