@import "../../styles/brand-colors";

.loader-dots {
  margin-top: -24px;
  font-size: 0;
  text-align: center;
}

.loader-dots > div {
  width: 16px;
  height: 16px;
  background-color: $lake;
  opacity: 0.2;
  margin: 0 4px;

  border-radius: 8px;
  display: inline-block;

  -webkit-animation: sk-bouncedelay 0.8s infinite ease-in-out both;
  animation: sk-bouncedelay 0.8s infinite ease-in-out both;
}

.loader-dots .bounce1 {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.loader-dots .bounce2 {
  -webkit-animation-delay: -0.15s;
  animation-delay: -0.15s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0.8);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

.loader-dots--sm > div {
  width: 8px;
  height: 8px;
  margin: 0 2px;
}

.loader-dots--dusk > div {
  background-color: $gray-one;
}

.loader-dots--midnight > div {
  background-color: $midnight;
}

.loader-dots-main {
  position: absolute;
  top: 25%;
  left: 50%;
}

@media screen and (max-width: 768px) {
  .loader-dots-main {
    margin-top: 10%;
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

